.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table, th, td {
    border: 0.1rem solid black;
    border-collapse: collapse;
    font-size: 1rem;
    /*background-color: grey;*/
}

th, td {
    /*padding:0.1rem;*/
    padding: 0.1rem 1rem 0.1rem 1rem;
    text-align: center;
}

th {
    background-color: grey;
}

td {
    background-color: lightgrey;
}

.sidebar {
  flex:  0 0 10rem;
}

.main-content {
  overflow-x: auto;
  white-space: nowrap;
}

.alt-table {
    counter-reset:row-num -1;
}
.alt-table tr {
    counter-increment:row-num;
}
.alt-table tr td:first-child::before {
    content:counter(row-num);
}

.update-date-div {
  display: inline-block;
  margin: 0.5rem;
}

.prof-table td {
  text-align: left;
}

.prof-table-div {
}

.inline-div {
  display: inline-block;
  vertical-align: top;
}

.prof-collapse-button {
  width: 20rem;
  text-align: left;
}

.prof-category-collapse-button {
  width: 15rem;
  text-align: left;
}

.prof-collapse-button:hover {
  background-color: darkgrey;
}

.prof-category-collapse-button:hover {
  background-color: darkgrey;
}

.site-header-right {
  float: right;
}

.recipe-list {
  background-color: lightgrey;
  border-style: solid;
  cursor: pointer;
  width: 18rem;
}

.recipe-list:hover {
  background-color: darkgrey;
}

.uncommon{
    border: ridge;
    border-color: #1EFF00;
    border-width: 4px;
    border-radius: 4px;
}
/*.uncommon-text{
    color: #FFFFFF;
}*/
.common{
    border: ridge;
    border-color: #FFFFFF;
    border-width: 4px;
    border-radius: 4px;
}
/*.common-text{
    color: #1EFF00;
}*/
.rare{
    border: ridge;
    border-color: #0070DD;
    border-width: 4px;
    border-radius: 4px;
}
/*.rare-text{
    color: #0070DD;
}*/
.epic{
    border: ridge;
    border-color: #A335EE;
    border-width: 4px;
    border-radius: 4px;
}
/*.epic-text{
    color: #A335EE;
}*/
.legendary{
    border: ridge;
    border-color: #FF8000;
    border-width: 4px;
    border-radius: 4px;
}
/*.legendary-text{
    color: #FF8000;
}*/

.uncollected{
    filter:  gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.mount-collapse-button {
  width: 22rem;
  text-align: left;
}

.mount-collapse-button:hover {
  background-color: darkgrey;
}

.mount-container {
  width: 22rem;
  display: flex;
  background-color: darkgrey;
  border: solid;
  align-items: center;
  justify-content: flex-end;
}

.pet-collapse-button {
  width: 22rem;
  text-align: left;
}

.pet-collapse-button:hover {
  background-color: darkgrey;
}

.pet-container {
  width: 22rem;
  display: flex;
  background-color: darkgrey;
  border: solid;
  align-items: center;
  justify-content: flex-end;
}

.left {
  margin: 0.3rem;
}

.right {
  margin: 0.3rem;
}

.Warrior{
    background-color: #C79C6E;
}
.Paladin{
    background-color: #F58CBA;
}
.Hunter{
    background-color: #ABD473;
}
.Rogue{
    background-color: #FFF569;
}
.Priest{
    background-color: #FFFFFF;
}
.Shaman{
    background-color: #0070DE;
}
.Mage{
    background-color: #69CCF0;
}
.Warlock{
    background-color: #9482C9;
}
.Monk{
    background-color: #00FF96;
}
.Druid{
    background-color: #FF7D0A;
}
.DemonHunter{
    background-color: #A330C9;
}
.DeathKnight{
    background-color: #C41F3B;  
}
.Evoker{
    background-color: #33937F;
}

.file-submit {
    margin-left: 5rem;
}